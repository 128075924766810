import React, { useState, createContext, useEffect } from "react";
import { SampleCard } from '../SurveyPage/sampleCard';
import Header, { FinalMessage } from './Header';
import FBManager from '../../Controller/FBManager';
import firebase from "../../Firebase.jsx";
import { Button, Row } from "react-bootstrap";
import { useLocation, useHistory } from "react-router-dom";
import { Modal, Divider, Progress, Checkbox } from "antd";

var db = firebase.firestore();
var imageManager = new FBManager();
var Brats = [];
var Neosoma = [];
var T1Data = [];
var T1cData = [];
var T2Data = [];
var FlairData = [];

const config = {
    title: "Warning",
    content: (<div>
        <label className="DialogLabel">Your Input is Needed !!</label>
        <label className="DialogLabel">Please choose one of the avaliable options</label>
    </div>
    )
};



const HomePage = () => {

    const history = useHistory();
    var locationstate = useLocation().state;
    var user = "Empty";
    if (useLocation().state === undefined) {
        history.push("/");
    } else {
        user = locationstate.userEmail;
    }


    const [modal, contextHolder] = Modal.useModal();
    const [userFLname, setUserFLname] = useState();
    const [userExp, setuserExp] = useState();
    const [specialty, setSpecialty] = useState();
    const [CurrentSample, setCurrentSample] = useState();
    const [Comment, setComment] = useState("N/A");
    const [isChained, setChained] = useState(true);
    const [imageIndex, setimageIndex] = useState(0);
    const [forceRender, setforceRender] = useState(0);
    const [sampleData, setsampleData] = useState("T1c");
    const [Gbrightness, setGbrightness] = useState(100);
    const [Gopacity, setGopacity] = useState(0);
    const [GConstrast, setGContrast] = useState(100);
    const [LastSampleAvaliable, setLastSampleAvaliable] = useState(true);
    var [counter, setCounter] = useState(0);
    const [SelectedCard, setSelectedCard] = useState('');
    const [TimeTake, setTimeTake] = useState('');
    const [SelectedBTN, setSelectedBTN] = useState('');
    const [TotalSamplesCount, setTotalSamplesCount] = useState(0);
    const [selectResult, setSelectResult] = useState('');
    const [imgPath, setImgPath] = useState('');
    var [admin, setAdmin] = useState(false);
    var [RandomGenerator, setRandomGenerator] = useState(1);
    var [Necrosis, setNecrosis] = useState(false);
    var [Enhancement, setEnhancement] = useState(false);
    var [Edema, setEdema] = useState(false);
    var [sampleRef, setSampleRef] = useState(false);
    var [mappingData, setMappingData] = useState({});


    const config2 = {
        title: "The selected segmentation had better:",
        cancelText: 'Dismiss',
        icon: null,
        onCancel: cancelBTN,
        onOk: okBTN,
        content: (<div style={{ display: 'flex', flexDirection: 'column' }}>
            <label style={{ marginLeft: '8px', fontSize: '10px', fontStyle: 'italic', fontWeight: 'bold', color: '#109abc' }}>Can select multiple options.</label>
            <Checkbox style={{ marginLeft: '8px' }}
                onChange={() => setNecrosis((prevState) => (Necrosis = !prevState))}>Necrosis segmentation</Checkbox>
            <Checkbox onChange={() => setEnhancement((prevState) => Enhancement = (!prevState))}>Enhancement segmentation</Checkbox>
            <Checkbox onChange={() => setEdema((prevState) => Edema = (!prevState))}>Edema segmentation</Checkbox>
        </div>
        )
    };

    useEffect(() => {
        db.collection("Constants").doc("data").get().then((doc) => {
            if (doc.exists) {
                setSelectResult(doc.data().setDataTo);
                setImgPath(doc.data().imgPath);
                setTotalSamplesCount(doc.data().surveyCount);
                setSampleRef(doc.data().sampleRef);
                setMappingData(doc.data().mappingData);
            }

        });
        setRandomGenerator(Math.floor((Math.random() * 2) + 1));
        clearAllData();
        imageManager.getImages(Brats, imgPath + CurrentSample + '/Sample');
        imageManager.getImages(Neosoma, imgPath + CurrentSample + '/Neosoma');
        imageManager.getImages(T1Data, imgPath + CurrentSample + '/T1');
        imageManager.getImages(T1cData, imgPath + CurrentSample + '/T1c');
        imageManager.getImages(T2Data, imgPath + CurrentSample + '/T2');
        imageManager.getImages(FlairData, imgPath + CurrentSample + '/Flair');
    }, [CurrentSample, imgPath]);

    if (LastSampleAvaliable) {
        getLastSample();
        setLastSampleAvaliable(false)
    }

    useEffect(() => {
        if (FlairData.length !== 155) {
            const currentDate = new Date();
            const timestamp = currentDate.getTime();
            setTimeTake(timestamp);
            const timer = setInterval(() => setCounter(counter + 1), 1000);
            return () => clearInterval(timer);
        } else {
            setforceRender(value => value + 1);
        }

    }, [counter]);

    if (CurrentSample > TotalSamplesCount) {
        return (<div>
            <Header admin={admin} userEmail={user} />
            <FinalMessage />
        </div>)
    } else {
        return (
            <div className="PageStyle">
                <Header admin={admin} userEmail={user} />
                <div className="ProgressBar ">
                    <Progress percent={(CurrentSample / TotalSamplesCount) * 100} size="Large" style={{ width: "200px" }}
                        status={CurrentSample > TotalSamplesCount ? "success" : "active"} showInfo={false} />
                    <label style={{ marginLeft: "20px" }}>{CurrentSample === undefined ? `0/${TotalSamplesCount}` : `${CurrentSample}/${TotalSamplesCount}`}</label>
                </div>
                <Divider />
                <h3 className="topTextstyle">Which Segmentation is Most Accurate?</h3>
                {sampleRef?
                <h1 className="topTextstyle">{mappingData[` Sample ${CurrentSample}`]}</h1>:<></>}
                <div className="HeaderStyle">
                    <DataContext.Provider value={{
                        isChained, setChained, imageIndex, setimageIndex,
                        Gopacity, setGopacity, Gbrightness, setGbrightness, sampleData, setsampleData, GConstrast, setGContrast, forceRender, setforceRender
                    }}>
                        <Row className="justify-content-center"> {contextHolder}
                            {RandomGenerator === 1 ?
                                <SampleCard data={[T1cData, FlairData, T2Data, T1Data, Brats, CurrentSample]} title="Brats" /> :
                                <SampleCard data={[T1cData, FlairData, T2Data, T1Data, Neosoma, CurrentSample]} title="Neosoma" />}
                            {RandomGenerator !== 1 ?
                                <SampleCard data={[T1cData, FlairData, T2Data, T1Data, Brats, CurrentSample]} title="Brats" /> :
                                <SampleCard data={[T1cData, FlairData, T2Data, T1Data, Neosoma, CurrentSample]} title="Neosoma" />}
                        </Row>
                    </DataContext.Provider>
                </div>
                <div className="UndecidedDiv">
                    <Button onClick={() => LeftBTNClicked()} id={'LeftClick' === SelectedBTN ? "submitBTnselected" : "submitBtn"}>Left</Button>
                    <Button onClick={() => UndecidedClicked()} id={'Undecided' === SelectedBTN ? "submitBTnselected" : "submitBtn"}>Undecided</Button>
                    <Button onClick={() => RightBTNClicked()} id={'RightClick' === SelectedBTN ? "submitBTnselected" : "submitBtn"}>Right</Button>
                </div>
                <Row className="justify-content-center BotDiv">
                    <input autoComplete="off" id="CommentArea" onChange={(value) => setComment(value.target.value)}
                        className="CommentStyle" type="text" placeholder="  Your Comment..." />
                    <Button onClick={getNextSample} id="submitBtn" type="button" >Next</Button>
                </Row>
            </div>
        );
    }

    function UndecidedClicked() {
        setSelectedBTN("Undecided");
        setSelectedCard('Undecided');
    }

    function RightBTNClicked() {
        setSelectedBTN("RightClick");
        RandomGenerator !== 1 ? setSelectedCard('Brats') : setSelectedCard('Neosoma');
    }

    function LeftBTNClicked() {
        setSelectedBTN("LeftClick");
        RandomGenerator === 1 ? setSelectedCard('Brats') : setSelectedCard('Neosoma');
    }

    function okBTN() {
        UpdateSelectedVote();
    }

    function cancelBTN() {
        ResetSegmentation();
        UpdateSelectedVote();
    }

    function getNextSample() {
        if (SelectedCard === 'Undecided') {
            UpdateSelectedVote();
        } else if (SelectedCard !== '') {
            modal.confirm(config2)
        } else {
            modal.warning(config);
        }
    }

    function UpdateSelectedVote() {
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        var secondsDifference = Math.floor((timestamp - TimeTake) / 1000);
        if (user !== null) {
            clearAllData();
            var sampleRef = "Sample " + CurrentSample;
            if (SelectedCard === 'UNDECIDED') {
                ResetSegmentation();
            }

            db.collection(selectResult).doc(sampleRef).set({
                [user]: [SelectedCard, Comment, secondsDifference, userFLname, specialty, userExp,
                    `Necrosis=${Necrosis}`, `Edema=${Edema}`, `Enhancement=${Enhancement}`],
            }, { merge: true })
                .then(() => {
                    setCurrentSample((previousValue) => previousValue + 1)
                    db.collection("users").doc(user).set({
                        CurrentSample: CurrentSample + 1,
                    }, { merge: true }).then(function () {
                        setCounter(0);
                        setGopacity(0);
                    }).catch(function (error) {
                        alert("Failed to save your vote", error);
                    });
                    UpdateTotalvotes(SelectedCard);
                    UpdateTotalvotes("TotalVotes");
                })
                .catch(function (error) {
                    alert("Failed to save your vote", error);
                });
            setSelectedCard('');
            setSelectedBTN('');
            ResetSegmentation();
            document.getElementById("CommentArea").value = null
        }
    }

    function ResetSegmentation() {
        setEdema(false);
        setEnhancement(false);
        setNecrosis(false);
    }

    function UpdateTotalvotes(voteType) {
        var docRef = db.collection(selectResult).doc("TotalVotes");
        docRef.get().then(function (doc) {
            if (doc.exists) {
                var currentVotes = 0;
                if (voteType === "Neosoma") {
                    currentVotes = (doc.data().Neosoma === undefined) ? 1 : doc.data().Neosoma + 1;
                } else if (voteType === "Brats") {
                    currentVotes = (doc.data().Brats === undefined) ? 1 : doc.data().Brats + 1;
                } else if (voteType === "Undecided") {
                    currentVotes = (doc.data().Undecided === undefined) ? 1 : doc.data().Undecided + 1;
                } else {
                    currentVotes = (doc.data().TotalVotes === undefined) ? 1 : doc.data().TotalVotes + 1;
                }
                docRef.set({
                    [voteType]: currentVotes,
                }, { merge: true })
            } else {
                docRef.set({
                    [voteType]: 1,
                }, { merge: true })
            }
        }).catch(function (error) {
            console.log("Error getting document:", error);
        });
    }

    function getLastSample() {
        var docRef = db.collection("users").doc(user);
        docRef.get().then(function (doc) {
            if (doc.exists) {
                setCurrentSample(doc.data().CurrentSample);
                setAdmin(doc.data().Admin);
                setUserFLname("" + doc.data().first + " " + doc.data().last);
                setSpecialty(doc.data().Specialty);
                setuserExp(doc.data().Experience);
            } else {
                console.log("No such document!");
            }
        }).catch(function (error) {
            console.log("Error getting document:", error);
        });
    }

    function clearAllData() {
        Brats.length = 0;
        Neosoma.length = 0;
        T2Data.length = 0;
        T1Data.length = 0;
        T1cData.length = 0;
        FlairData.length = 0;
    }
}



export default HomePage;
export var DataContext = createContext(null);