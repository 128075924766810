import React, { useEffect, useState } from "react";
import { Radio, message } from 'antd';
import firebase from "../../Firebase.jsx";


var db = firebase.firestore();

export default function HistoricalData() {

    var [sourceData, setSourceData] = useState([]);
    var [currentSelectedData, setCurrentSelectedData] = useState('');

    useEffect(() => {
        db.doc("Constants/data").get().then((doc) => {
            if (doc.exists) {
                setSourceData(doc.data().viewData);
                setCurrentSelectedData(doc.data().readFrom);
            }
        });
    }, []);

    function saveFunc() {
        message
            .loading('Action in progress..', 0);
        for (let i = 0; i < sourceData.length; i++) {
            if (sourceData[i]["key"] === currentSelectedData) { 
                db.doc("Constants/data").update({
                    readFrom: currentSelectedData,
                    sampleName:sourceData[i]["year"].split("-")[0],
                    hasMapping:sourceData[i]["hasMapping"]
                }).then(() => {
                    message.destroy();
                    message.success('Changes has been saved!', 2.5)
                });
            }
        }

    }


    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
            {currentSelectedData !== '' ? <Radio.Group defaultValue={currentSelectedData} buttonStyle="solid"
                onChange={(val) => {
                    setCurrentSelectedData(val.target.value);
                }}>
                {sourceData.map((item, index) => (
                    <Radio.Button className="radioTxtBigger" value={item["key"] === [] ? index : item["key"]}>{item["year"]}</Radio.Button>))}
            </Radio.Group> : <></>}
            <button onClick={saveFunc} type="button" className="smallerBTN">
                Save Changes
            </button>
        </div>
    );
}