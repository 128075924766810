import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import loginImg from "../../Assets/neosoma.jpeg";
import firebase from "../../Firebase.jsx";
import { message } from 'antd';
import 'antd/dist/reset.css'; 

const Login = () => {
  var db = firebase.firestore();
  const [ResertPW, setResertPW] = useState(false);
  const history = useHistory();
  const handleClick = (userEmail, url) => history.push(url, { userEmail: userEmail });


  useEffect(() => { 
    if (ResertPW) {
      setTimeout(() => {
        document.getElementsByClassName("form-group fading")[0].hidden = true
      }, 250);
    } else {
      document.getElementsByClassName("form-group fadingin")[0].hidden = false
    }
  }, [ResertPW])

  function LoginFunction() {
    message
      .loading('Action in progress..', 0)
    var email = document.getElementById("emailTextField").value;
    var password = document.getElementById("passwordTextField").value;
    if (email !== "" || password !== "") {
      firebase.auth().signInWithEmailAndPassword(email, password)
        .then((user) => { 
          var UserMetadata = firebase.auth().currentUser.metadata;
          var currenturl = window.location.href;
          if (currenturl.includes("verifyEmail")) {
            db.collection("users").doc(email).update({
              EmailVerified: true,
              createdAt: UserMetadata.creationTime,
              lastSeen: UserMetadata.lastSignInTime
            }).then(function () {
              message.destroy();
              message.success('Access Granted !', 2.5).then(() => handleClick(user.user.email, '/getstarted'));
            })
          } else {
            db.collection("users").doc(email).update({ 
              lastSeen: UserMetadata.lastSignInTime
            }).catch(() => {
              console.log("Failed to Add last seen and creation time");
            });
            getUserInfo(user.user.email);
          }
        })
        .catch((error) => {
          message.destroy();
          if (error.message === "The email address is badly formatted.") {
            message.error("The email address is invalid.", 2.5);
          } else {
            message.error(error.message, 2.5);
          }
        });
    } else {
      message.destroy();
      message.error("Missing Email or Password !", 2.5);
    }
  }

  function ResetPassword() {
    var Auth = firebase.auth();
    var email = document.getElementById("emailTextField").value;
    Auth.sendPasswordResetEmail(email).then(function () {
      message.success('Please check your email a reset password email has been sent !', 1.5).then(() => setResertPW(!ResertPW))
    }).catch(function (error) {
      message.error(error.message, 2.5);
    });
  }

  function getUserInfo(userEmail) {
    var docRef = db.collection("users").doc(userEmail);
    docRef.get().then(function (doc) {
      if (doc.exists) {
        if (!doc.data().EmailVerified) {
          message.destroy();
          message.warning("Please check your email a verification email has been sent !", 2);
        } else {
          message.destroy();
          message.success('Access Granted !', 2.5).then(() =>
            doc.data().InstructionsFinished ? handleClick(userEmail, '/home') : handleClick(userEmail, '/getstarted'))
        }
      } else {
        console.log("No such document!");
      }
    }).catch(function (error) {
      console.log("Error getting document:", error);
    });
  }

  return (
    <div className="base-container fadingin">
      <div className="content">
        <div className="image">
          <img id="speceficeImage" alt="" src={loginImg} />
        </div>
        <div className="form">
          <div className="form-group">
            <label htmlFor="username">E-mail</label>
            <input autoComplete="off" id="emailTextField" type="text" placeholder="E-mail" />
          </div>
          <div className={ResertPW ? "form-group fading" : "form-group fadingin"}>
            <label htmlFor="password">Password</label>
            <input onKeyPress={(e) => e.key === "Enter" ? LoginFunction() : null} autoComplete="off" id="passwordTextField" type="password" name="password" placeholder="Password" />
          </div>
        </div>
      </div>
      <div className="footer">
        <button onClick={() => setResertPW(!ResertPW)} className="ResetPW" >
          {ResertPW ? "Return to Sign in" : "Forgot Password ?"}
        </button>
        <button onClick={ResertPW ? ResetPassword : LoginFunction} type="button" className="btn">
          {ResertPW ? "Reset Password" : "Login"}
        </button>
      </div>
    </div>
  );
}
export { Login };

