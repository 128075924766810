import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import TabPage from './Components/Tabs/TabPage';
import NotFound from './Components/SurveyPage/NotFound';
import GetStarted from './Components/login/gettingstarted';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import HomePage from './Components/SurveyPage/HomePage';
import './index.css';


import reportWebVitals from './reportWebVitals';
import { ResetPasswod } from './Components/login/ResetPassword';


const WebStructure = () => {
  return (<Router>
    <Switch>
      <Route exact path="/" component={App} />
      <Route path="/home" component={HomePage} />
      <Route path="/insights" component={TabPage} />
      <Route path="/getstarted" component={GetStarted} />
      <Route path="/resetpassword" component={ResetPasswod} />
      <Route exact path="*" component={NotFound} />
    </Switch>
  </Router>);
}

ReactDOM.render(
  <WebStructure />,
  document.getElementById('root')
);

reportWebVitals();
