import Button from 'react-bootstrap/Button';
import React, { useState } from "react";
import loginImg from "../../Assets/neosoma.png";
import { useHistory } from "react-router-dom";
import firebase from "../../Firebase.jsx";
import { Result, Drawer } from "antd";
import { SmileOutlined, MenuOutlined } from '@ant-design/icons';


const Header = (props) => {
    const history = useHistory();
    const [hidebtns, sethidebtns] = useState(false);
    const [sideNavState, setsideNavState] = useState(false);
    const placement = this;
    window.addEventListener('resize', handleResize);


    const TopBTNS = (props2) => {
        return (<div hidden={props.isVisible} style={props2.state === true ? { display: 'flex', flexDirection: 'column' } : null}>
            <Button className="TopHeadeBTNs" onClick={() => history.push('/home', { userEmail: props.userEmail })} hidden={!props.admin}>Home</Button>
            <Button className="TopHeadeBTNs" onClick={() => history.push('/insights', { userEmail: props.userEmail })} hidden={!props.admin}>Survey Info</Button>
            <Button className="TopHeadeBTNs" onClick={Signout} hidden={props.hideSign} >Sign out</Button>
        </div>)
    }

    const header = {
        imageStyle: {
            marginLeft: '10px',
            width: '60px',
            height: '60px',
            alignItems: 'center',
        }, headerStyle: {
            backgroundColor: '#142850',
            display: 'flex',
            flexDirection: 'row',
            boxShadow: '2px 3px 2px 2px #394867'
        }
    };

    function handleResize() {
        if (window.innerWidth < 700) {
            sethidebtns(true);
        } else {
            sethidebtns(false);
        }
    }

    return (
        <div style={{ position: "relative" }}>
            <div style={header.headerStyle}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <img style={header.imageStyle} alt="" src={loginImg} />
                    <h2 className="HeaderTitle" style={{ marginTop: '5px' }}>Neosoma</h2>
                </div>
                <div style={{ position: "absolute", right: "0px", height: '100%' }}>
                    <div hidden={hidebtns}>
                        <TopBTNS state={false} />
                    </div>
                    <div hidden={!hidebtns} onClick={() => setsideNavState(true)}>
                        <MenuOutlined className="MenuItem" />
                    </div>
                </div>
            </div>
            <Drawer
                placement={placement}
                closable={false}
                onClose={() => setsideNavState(false)}
                open={sideNavState}
                key={placement} >
                <div>
                    <TopBTNS state={true} />
                </div>
            </Drawer>
        </div>
    );

    function Signout() {
        firebase.auth().signOut().then(() => {
            history.push('/');
        }).catch((error) => {
            console.log("Cannot Signout");
        });
    }
}

const FinalMessage = () => {
    return (<Result
        icon={<img style={{ height: '120px' }} alt="" src={loginImg} />}
        title={<label style={{ fontStyle: "italic", fontWeight: 'bold', color: '#109abc' }}>We have Successfully received your results !</label>}
        subTitle={
            <div>
                <label style={{ fontStyle: "italic", marginRight: "5px" }}>Thank you very much</label>
                <SmileOutlined twoToneColor="#109abc" />
            </div>
        }
    />);
}
export default Header;
export { FinalMessage };