

import { Excel } from "antd-table-saveas-excel";

export default class ExcelExporter {
    static exportData(fileName,sheetName,dataStructure,dataSource) { 
        const excel = new Excel();
        excel
            .addSheet(sheetName)
            .addColumns(dataStructure)
            .addDataSource(dataSource, {
                str2Percent: true
            })
            .saveAs(fileName);
    }



}