import React from "react";
import { useHistory } from "react-router-dom";
import { Result, Button } from 'antd';
import Header from "./Header";


const NotFound = () => {
    const history = useHistory();

    return (
        <div >
            <Header hideSign={true}/>
            <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={<Button style={{width:'150px'}} onClick={() =>history.push('/')} type="primary">Back To Login</Button>}
            />
        </div>
    );

   
}


export default NotFound;