import React from "react";
import loginImg from "../../Assets/neosoma.jpeg";
import firebase from "../../Firebase.jsx";
import { message } from 'antd';
import { useHistory } from "react-router";


const ResetPasswod = () => {

    var Auth = firebase.auth();
    const history = useHistory();

    function ResetingPassword() {
        const urlParams = new URLSearchParams(window.location.href);
        var newpw = document.getElementById("newpw").value;
        var confignewpw = document.getElementById("confignewpw").value;
        if (newpw !== confignewpw) {
            message.error("Password Mismatch !", 2.5);
        } else {
            Auth.confirmPasswordReset(urlParams.get("oobCode"), newpw).then(() => {
                message.success("Password Changed !", 1.5).then(() => history.push("/"));
            }).catch((error) => {
                message.destroy();
                message.error(error.message, 2.5);
            });
        }
    }
    
    return (
        <div className="App">
            <div className="login">
                <div className="container">
                    <div className="base-container">
                        <div className="content">
                            <div className="image">
                                <img id="speceficeImage" alt="" src={loginImg} />
                            </div>
                            <div className="form">
                                <div className="form-group">
                                    <label>New Password</label>
                                    <input autoComplete="off" id="newpw" type="password" placeholder="New Password" />
                                </div>
                                <div className="form-group">
                                    <label>Confirm New Password</label>
                                    <input autoComplete="off" id="confignewpw" type="password" name="password" placeholder="Confirm New Password" />
                                </div>
                            </div>
                        </div>
                        <button onClick={ResetingPassword} type="button" className="btn">
                            Reset Now
                        </button>
                    </div>
                </div>

            </div>
        </div>)
}
export { ResetPasswod };