import React, { useState, useRef, useEffect, cloneElement } from 'react'
import { Tabs, useTabState, Panel } from '@bumaga/tabs'
import TableData from "../Tabs/TableData";
import ProfileInfo from "../Tabs/ProfileInfo";
import CommentsTable from "../Tabs/CommentsTable";
import Piechart from "../Tabs/Piechart";
import { PieChartOutlined, TableOutlined, UserOutlined , DatabaseOutlined} from '@ant-design/icons';
import { motion, AnimatePresence } from 'framer-motion';
import "./tabBar.scss";
import HistoricalData from './dataMangement';
import SegregationsTable from './segregationsTable';
//This is the final version
const cn = (...args) => args.filter(Boolean).join(' ')
const Tab = ({ children }) => {
    const { isActive, onClick } = useTabState()

    return (
        <button className={cn('tab', isActive && 'active')} onClick={onClick}>
            {children}
        </button>
    )
}

const PanelList = ({ state, children }) => {
    const panelRef = useRef()
    const [height, set] = useState(0)
    const [activeIndex] = state

    useEffect(() => {
        panelRef.current && set(panelRef.current.offsetHeight)
    }, [activeIndex, set])

    return (
        <motion.ul className='panel-list' animate={{ height }}>
            <AnimatePresence initial={false}>
                <motion.li
                    ref={panelRef}
                    key={activeIndex}
                    initial={{ opacity: 0, x: -32 }}
                    animate={{ opacity: 1, x: 0, transition: { delay: 0.1, ease: 'easeInOut', duration: 0.2 } }}
                    exit={{ opacity: 0, x: 32, transition: { ease: 'easeInOut', duration: 0.2 } }}
                >
                    {cloneElement(children[activeIndex], { active: true })}
                </motion.li>
            </AnimatePresence>
        </motion.ul>
    )
}

export default function AnimatedTab() {
    const state = useState(0)

    return (
        <Tabs state={state}>
            <div className='tabs'>
                <div className='tab-list'>
                    <Tab><PieChartOutlined />  Overall Result</Tab>
                    <Tab><TableOutlined />  Detailed</Tab>
                    <Tab><TableOutlined />  User Comments</Tab>
                    <Tab><TableOutlined />  Segregations</Tab>
                    <Tab><DatabaseOutlined />  Historical Data</Tab>
                    <Tab><UserOutlined />  Profiles</Tab>
                </div>
                <div className={state.includes(0) ? "PanelHolder" : "TableClass"}>
                    <PanelList state={state}>
                        <Panel>
                            <Piechart />
                        </Panel>
                        <Panel>
                            <TableData />
                        </Panel>
                        <Panel>
                            <CommentsTable />
                        </Panel>
                        <Panel>
                            <SegregationsTable />
                        </Panel>
                        <Panel>
                            <HistoricalData />
                        </Panel>
                        <Panel>
                            <ProfileInfo />
                        </Panel>
                    </PanelList>
                </div>
            </div>
        </Tabs>
    )
}