import React, { useEffect, useState } from 'react'
import { Table, Popover } from "antd";
import firebase from "../../Firebase.jsx"; 
import { TableSkeletonview } from '../SurveyPage/SkeleltonView';
import { SegregationsUser, SegregationsUserDetailed } from '../../Model/Comment';
import ExcelExporter from '../../Controller/fileExporter.js';

var db = firebase.firestore();

export default function SegregationsTable() {
    var [segregatedData, setSegregatedData] = useState([]); 
    var [sampleName, setSampleName] = useState("");
    var [readFrom, setReadFrom] = useState("");
    var [hasMapping, sethasMapping] = useState(false);
    var [dataStructure, setDataStructure] = useState([]);
    var [mappingData, setMappingData] = useState({});
    var [ArrayFilled, setArrayFilled] = useState(false);
    var [users, setUsers] = useState([]);

    useEffect(() => {
        document.body.style.backgroundColor = "#dae1e7";
        db.doc("Constants/data").get().then((doc) => {
            if (doc.exists) {
                setSampleName(doc.data().sampleName);
                setMappingData(doc.data().mappingData);
                sethasMapping(doc.data().hasMapping);
                if (segregatedData.length === 0) {
                    loadUsersFirstSample();
                }
            }
        });
    }, [segregatedData.length]);


    useEffect(() => {
        if (users.length !== 0) {
            var result = []; 
            users.forEach(async function (user) {
                var segregatedUser = new SegregationsUser('', 0, 0, 0, 0, [], [], []); 
                var querySnapShot = await db.collection(readFrom).get();
                querySnapShot.forEach(function (userVoteSampleCollection) {
                    if (userVoteSampleCollection.data()[user] !== undefined && userVoteSampleCollection !== "TotalVotes") {
                        const singleUserVoteData = userVoteSampleCollection.data()[user];
                        segregatedUser.userName = singleUserVoteData[3];
                        if (singleUserVoteData[0] === "Neosoma") {
                            segregatedUser.totalNeosoma += 1;
                            segregatedUser.neosomaSamples.push(userVoteSampleCollection.id);
                        }
                        if (singleUserVoteData[0] === "Brats") {
                            segregatedUser.totalBrats += 1;
                            segregatedUser.bratsSamples.push(userVoteSampleCollection.id);
                        }
                        if (singleUserVoteData[0] === "Undecided") {
                            segregatedUser.totalUndecided += 1;
                            segregatedUser.undecidedSamples.push(userVoteSampleCollection.id)
                        }

                        segregatedUser.totalConducted = segregatedUser.totalNeosoma + segregatedUser.totalBrats + segregatedUser.totalUndecided;
                    }
                });
                result.push(segregatedUser); 
                setSegregatedData([...result]); 
            });
            setArrayFilled(true);
        }
    }, [users, readFrom])


    useEffect(() => {
        if (ArrayFilled) {
            setDataStructure([
                {
                    title: 'Reviewer',
                    dataIndex: "userName",
                    key: 1,
                    fixed: "left",
                    align: 'center'
                },
                {
                    title: "Neosoma Count",
                    dataIndex: "totalNeosoma",
                    key: 2,
                    align: 'center',
                    render: (tag, r, index) => (
                        <>
                            <Popover content={(
                                <div style={{overflowY:'scroll',height:'200px',}}>  
                                {segregatedData[index]!==undefined? (segregatedData[index].neosomaSamples.map(val =>{
                                    return(<p>{hasMapping?val+" | "+mappingData[` ${val}`]:val}</p>)
                                })):<></>} 
                                <button className='exportBTN'onClick={()=>ExportUserDetailed(segregatedData[index].neosomaSamples,segregatedData[index].userName,"Neosoma")}>Export to Excel</button>
                                </div>
                            )} title="Neosoma Samples ">
                                {tag}
                            </Popover>
                        </>
                    )
                },
                {
                    title: `${sampleName} Count`,
                    dataIndex: "totalBrats",
                    key: 3,
                    align: 'center',
                    render: (tag,r,index) => (
                       tag!==0? <>
                       <Popover content={
                            <div style={{overflowY:'scroll',height:'200px',}}>  
                           {segregatedData[index]!==undefined? (segregatedData[index].bratsSamples.map(val =>{
                            return(<p>{hasMapping?val+" | "+mappingData[` ${val}`]:val}</p>)
                           })):<></>}
                                <button className='exportBTN' onClick={()=>ExportUserDetailed(segregatedData[index].bratsSamples,segregatedData[index].userName,sampleName)}>Export to Excel</button>
                           </div>
                           } title={sampleName + " Samples"}>
                           {tag}
                       </Popover>
                   </>:<>{tag}</>
                    )
                },
                {
                    title: "Undecided Count",
                    dataIndex: "totalUndecided",
                    key: 4,
                    align: 'center',
                    render: (tag,r,index) => (
                       tag!==0? <>
                       <Popover content={
                       <div style={{overflowY:'scroll',height:'200px',}}>  
                           {segregatedData[index]!==undefined? (segregatedData[index].undecidedSamples.map(val =>{
                            return(<p>{hasMapping?val+" | "+mappingData[` ${val}`]:val}</p>)
                           })):<></>}
                           <button className='exportBTN' onClick={()=>ExportUserDetailed(segregatedData[index].undecidedSamples,segregatedData[index].userName,"Undecided")}>Export to Excel</button>
                           </div>
                       } title="Undecided Samples ">
                           {tag}
                       </Popover>
                   </>:<>{tag}</>
                    )
                },
                {
                    title: "Total Conducted",
                    dataIndex: "totalConducted",
                    key: 5,
                    align: 'center'
                }
            ]); 
        }
    }, [ArrayFilled, segregatedData, sampleName,hasMapping,mappingData,users])


function ExportUserDetailed(data,user,selectedSample){
var finalData = [];
    const dataStructurecolumns = [
        {
            title: 'Reviewer',
            dataIndex: "userName",
            key: 1,
            fixed: "left",
            align: 'center'
        },
        {
            title: "Samples",
            dataIndex: "sampleName",
            key: 2,
            align: 'center',
        },
        {
            title: "Selected Product",
            dataIndex: "selectedSample",
            key: 3,
            align: 'center',
        }
    ];

    data.forEach(singleEntry => { 
        finalData.push(new SegregationsUserDetailed(user,hasMapping?singleEntry+" | "+mappingData[` ${singleEntry}`]:singleEntry,selectedSample));
    });
    ExcelExporter.exportData(`segregatedUser_Details_${user}.xlsx`,`segregatedUser_Details_${selectedSample}`, dataStructurecolumns, finalData);
 
}

    function loadUsersFirstSample() {
        db.collection("Constants").doc("data").get().then((doc) => {
            if (doc.exists) {
                setReadFrom(doc.data().readFrom);
                db.doc(`${doc.data().readFrom}/Sample 1`).get().then((usersSurvey) => {
                    if (usersSurvey.exists) {
                        setUsers(Object.keys(usersSurvey.data()));
                    }
                });
            }
        });
    }

    return (!ArrayFilled && segregatedData.length !== 0 ?
        <TableSkeletonview /> : <div><Table size="small" columns={dataStructure} dataSource={segregatedData} />
            <button onClick={() => {
                var dataStruc=[
                {
                    title: 'Reviewer',
                    dataIndex: "userName",
                    key: 1,
                    fixed: "left",
                    align: 'center'
                },
                {
                    title: "Neosoma Count",
                    dataIndex: "totalNeosoma",
                    key: 2,
                    align: 'center',
                  
                },
                {
                    title: `${sampleName} Count`,
                    dataIndex: "totalBrats",
                    key: 3,
                    align: 'center',
                 
                },
                {
                    title: "Undecided Count",
                    dataIndex: "totalUndecided",
                    key: 4,
                    align: 'center',
                    
                },
                {
                    title: "Total Conducted",
                    dataIndex: "totalConducted",
                    key: 5,
                    align: 'center'
                }
            ]; 
                ExcelExporter.exportData("segregatedData.xlsx", "segregatedData", dataStruc, segregatedData)
            }}
                className="smallerBTN" >Export to Excel</button>
        </div>);
}