import firebase from "firebase"

var firebaseConfig = {
  apiKey: "AIzaSyBVV2_6tsYkIBwWLzVfQqBd7Xqk6xV5-TM",
  authDomain: "neosoma-groundtruth.firebaseapp.com",
  projectId: "neosoma-groundtruth",
  storageBucket: "neosoma-groundtruth.appspot.com",
  messagingSenderId: "849140311015",
  appId: "1:849140311015:web:3afba95b4131bbe9e71342",
  measurementId: "G-PV23PDN6GN"
};

const firebasesetup = firebase.initializeApp(firebaseConfig);

export default firebasesetup;