import React, { useState } from "react";
import firebase from "../../Firebase.jsx";
import { message, Radio } from 'antd';
import 'antd/dist/reset.css';

var db = firebase.firestore();

const Register = (props) => {

  const [Exp, setExp] = useState('');

  return (
    <div className="base-container" ref={props.containerRef}>
      <div className="content">
        <div className="form">
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="form-group">
              <label htmlFor="username">First Name</label>
              <input autoComplete="off" id="FirstID" type="text" placeholder="First Name" />
            </div>
            <div className="form-group">
              <label htmlFor="username">Last Name</label>
              <input autoComplete="off" id="lastID" type="text" placeholder="Last Name" />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="username">Speciality</label>
            <input autoComplete="off" id="SpecialityID" type="text" placeholder="Speciality" />
          </div>
          <label className="DropdownText" htmlFor="username">Experience</label>
          <Radio.Group defaultValue="Resident" buttonStyle="solid" 
            onChange={(val) => {
              setExp(val.target.value);
            }}>
            <Radio.Button className="radioTxt" value="Resident">Resident</Radio.Button>
            <Radio.Button className="radioTxt" value="Fellow">Fellow</Radio.Button>
            <Radio.Button className="radioTxt" value="Attending">Attending</Radio.Button>
            <Radio.Button className="radioTxt" value="Researcher">Researcher</Radio.Button>
          </Radio.Group>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input autoComplete="off" id="emailID" type="text" placeholder="Email" />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input autoComplete="off" id="passwordID" type="password" name="password" placeholder="Password" />
          </div>
          <div className="form-group">
            <label htmlFor="password">Confirm Password</label>
            <input autoComplete="off" id="ConpasswordID" type="password" name="password" placeholder="Confirm Password" />
          </div>
        </div>
      </div>
      <div className="footer">
        <button onClick={RegisterFunc} type="button" className="btn">
          Register
        </button>
      </div>
    </div>
  );
  function RegisterFunc() {
    var FirstName = document.getElementById("FirstID").value;
    var LastName = document.getElementById("lastID").value;
    var Specialty = document.getElementById("SpecialityID").value;
    var email = document.getElementById("emailID").value.toLowerCase();    ;
    var password = document.getElementById("passwordID").value;
    var Confirmpassword = document.getElementById("ConpasswordID").value;

    message
      .loading('Action in progress..', 0);

    if (FirstName !== "" && LastName !== "" && Specialty !== "" && password !== "" && email !== "" && Confirmpassword !== "" && Exp !== "") {
      if (Confirmpassword !== password) {
        message.destroy();
        message.error("Password Mismatch !", 2.5);
      } else {
        firebase.auth().createUserWithEmailAndPassword(email, password)
          .then((user) => {
            db.collection("users").doc(email).set({
              first: FirstName,
              last: LastName,
              Specialty: Specialty,
              email: email,
              CurrentSample: 1,
              Experience: Exp,
              Admin: false,
              InstructionsFinished: false,
              EmailVerified: false,  
              createdAt: "",
              lastSeen:""
            })
              .then(function () {
                message.destroy();
                message.success("Profile Created Succesfully ", 1).then(() => {
                  firebase.auth().currentUser.sendEmailVerification().then(function () {
                    message.success('A verification Email has been sent !', 2);
                    props.switchToLogin();
                  }).catch(function (error) {
                    message.error('Failed to send Verification Email ' + error, 2);
                  });
                });
              })
              .catch(function (error) {
                message.destroy();
                message.error("Failed to create profile " + error, 2.5);
              });

          })
          .catch((error) => {
            message.destroy();
            if (error.message === "The email address is badly formatted.") {
              message.error("The email address is invalid.", 2.5);
            } else {
              message.error(error.message, 2.5);
            }
          });
      }
    } else {
      message.destroy();
      message.error("Please complete all fields to register", 2.5);
    }
  }
}
export { Register };