import React, { useEffect, useState } from 'react'
import { Table } from "antd";
import firebase from "../../Firebase.jsx"; 
import { DetailedTable } from "../../Model/Comment";
import { TableSkeletonview } from '../SurveyPage/SkeleltonView';
import ExcelExporter from '../../Controller/fileExporter.js';

var db = firebase.firestore();

export default function TableData() {
    var [Comments, setComments] = useState([]);
    var [ArrayFilled, setArrayFilled] = useState(false);
    var [sampleName, setSampleName] = useState("");
    var [hasMapping, sethasMapping] = useState(false);
    var [mappingData, setMappingData] = useState({});

    useEffect(() => {
        db.doc("Constants/data").get().then((doc) => {
            if (doc.exists) {
                setSampleName(doc.data().sampleName);
                setMappingData(doc.data().mappingData);
                sethasMapping(doc.data().hasMapping);
            }
        });
    }, []);

    if (!ArrayFilled) {
        getTablesInfo();
    }

    function getTablesInfo() {
        db.collection("Constants").doc("data").get().then((doc) => {
            if (doc.exists) {
                var readFrom = doc.data().readFrom;
                var Comments = [];
                db.collection(readFrom).get().then(function (querySnapshot) {
                    querySnapshot.forEach(function (doc) {
                        if (doc.id !== "TotalVotes") {
                            const userVotes = Object.keys(doc.data());
                            var TNeosom = 0;
                            var Ttime = 0;
                            var Tundecided = 0;
                            var TBrats = 0;
                            userVotes.forEach(function (user) {
                                const ArrayofInfo = doc.data()[user];
                                switch (ArrayofInfo[0]) {
                                    case "Neosoma":
                                        TNeosom += 1;
                                        break;
                                    case "Brats":
                                        TBrats += 1;
                                        break;
                                    default: Tundecided += 1;
                                }
                                Ttime += ArrayofInfo[2];
                            });
                            if (mappingData[` ${doc.id}`] !== undefined) {
                                const SingleEntry = new DetailedTable(doc.id,mappingData[` ${doc.id}`], userVotes.length, TBrats,
                                    TNeosom, Tundecided, `${parseInt(Ttime / userVotes.length)} seconds`); 
                                Comments.push(SingleEntry);
                            }
                        }
                        setComments(() => [...Comments]);
                        setArrayFilled(true);
                    });
                });
            }

        });
    }

    var columns = [ 
        {
            title: "Sample Number",
            dataIndex: "samlpleNumber",
            key: "samlpleNumber",
            fixed: "left",
            align: 'center'
        },
        {
            title: "Total Vote",
            dataIndex: "totalVote",
            key: "totalVote",
            fixed: "left",
            align: 'center'
        },
        {
            title: "Neosoma",
            dataIndex: "NeosomaVote",
            key: "1",
            align: 'center'
        },
        {
            title: sampleName,
            dataIndex: "BratsVotes",
            key: "2",
            align: 'center'
        },
        {
            title: "Undecided",
            dataIndex: "UDvotes",
            key: "3",
            align: 'center'
        },
        {
            title: "Average Time",
            dataIndex: "Time",
            key: "3",
            align: 'center'
        }
    ];
 

    if(hasMapping){
        columns=[{
            title:'Reference Sample',
            dataIndex: "sample",
            key: "sample",
            fixed: "left",
            align: 'center'
        },...columns];
    }

    return (
        <div> 
            {!ArrayFilled  ? <TableSkeletonview /> : 
            <div><Table size="small" columns={columns} dataSource={Comments} />
                <button onClick={()=>ExcelExporter.exportData(`${sampleName}_usersInsights.xlsx`,"usersInsights",columns,Comments)}  className="smallerBTN" >Export to Excel</button></div>}
        </div>
    );
}