import React from "react";
import Header from "../SurveyPage/Header";
import { useLocation,useHistory } from "react-router-dom";
import AnimatedTab from "../Tabs/animatedTab"

const TabPage = () => { 
    const history = useHistory();
    var locationstate = useLocation().state;
    var user = "Empty";
    if (useLocation().state === undefined) {
        history.push("/");
    } else {
        user = locationstate.userEmail;
    }
    return (
        <div className="PageStyle">
            <Header admin={true} userEmail={user} />
            <AnimatedTab />
        </div>
    );
}
export default TabPage;