import React, { useEffect, useState } from 'react'
import { Table, Tag, } from "antd";
import firebase from "../../Firebase.jsx";
import Comment from "../../Model/Comment"; 
import { TableSkeletonview } from '../SurveyPage/SkeleltonView';
import ExcelExporter from '../../Controller/fileExporter.js';

var db = firebase.firestore();

export default function CommentsTable() {
    var [Comments, setComments] = useState([]);
    var [ArrayFilled, setArrayFilled] = useState(false);
    var [sampleName, setSampleName] = useState("");
    var [hasMapping, sethasMapping] = useState(false);
    var [mappingData, setMappingData] = useState({});

    useEffect(() => {
        document.body.style.backgroundColor="#dae1e7";
        db.doc("Constants/data").get().then((doc) => {
            if (doc.exists) {
                setSampleName(doc.data().sampleName);
                setMappingData(doc.data().mappingData);
                sethasMapping(doc.data().hasMapping);
            }
        });
    }, []);
  

    if (!ArrayFilled) {
        getTablesInfo();
    }

    function getTablesInfo() {
        db.collection("Constants").doc("data").get().then((doc) => {
            var Comments = [];
            if (doc.exists) {
                var readFrom = doc.data().readFrom;
                db.collection(readFrom).get().then(function (querySnapshot) { 
                    querySnapshot.forEach(function (doc) {
                        if (doc.id !== "TotalVotes") {
                            const userVotes = Object.keys(doc.data());
                            userVotes.forEach(function (user) {
                                const ArrayofInfo = doc.data()[user];
                                var selectedSegmentation = "Better: "
                                if (ArrayofInfo[6].split("=")[1] === "true") {
                                    if (ArrayofInfo[0] === "Brats") {
                                        selectedSegmentation += `${sampleName}_Necrosis,`
                                    } else {
                                        selectedSegmentation += "Neosoma_Necrosis,"
                                    }
                                }
                                if (ArrayofInfo[7].split("=")[1] === "true") {
                                    if (ArrayofInfo[0] === "Brats") {
                                        selectedSegmentation += `${sampleName}_Edema,`
                                    } else {
                                        selectedSegmentation += "Neosoma_Edema,"
                                    }
                                }
                                if (ArrayofInfo[8].split("=")[1] === "true") {
                                    if (ArrayofInfo[0] === "Brats") {
                                        selectedSegmentation += `${sampleName}_Enhancement`
                                    } else {
                                        selectedSegmentation += "Neosoma_Enhancement,"
                                    }
                                }
                                if (ArrayofInfo[8].split("=")[1] === "false" && ArrayofInfo[6].split("=")[1] === "false" && ArrayofInfo[7].split("=")[1] === "false") {
                                    selectedSegmentation = "N/A"
                                }
                                if (mappingData[` ${doc.id}`] !== undefined) { 
                                    const SingleComment = new Comment(ArrayofInfo[3],hasMapping?mappingData[` ${doc.id}`]:doc.id, ArrayofInfo[1], [ArrayofInfo[0]],
                                        `${ArrayofInfo[2]} Seconds`, ArrayofInfo[4], ArrayofInfo[5], selectedSegmentation, Math.random());
                                    Comments.push(SingleComment);
                                } 
                            });
                        }
                        setComments(() => [...Comments]);
                        setArrayFilled(true); 
                    });
                });
            }
        });
    }

const columns = [
    {
        title:'',
        dataIndex: "name",
        key: "name",
        fixed: "left",
        align: 'center'
    },
    {
        title: "Speciality",
        dataIndex: "Specialty",
        key: "4",
        fixed: "left",
        align: 'center'
    },
    {
        title: "Experience",
        dataIndex: "Experience",
        align: 'center'
    },
    {
        title: "Refrence Sample",
        dataIndex: "sample",
        key: "1",
        align: 'center'
    },
    {
        title: "Comments",
        dataIndex: "input",
        key: "2",
        align: 'center'
    },
    {
        title: "Selected Segmentation",
        dataIndex: "selectedSegmentation",
        key: "2",
        align: 'center',
    },
    {
        title: "Selected Sample",
        dataIndex: "selectedSample",
        key: "3",
        align: 'center',
        render: (tags) => (
            <>
                {tags.map((tag) => {
                    var color = "#00b7c2"; 
                    if (tag === "Undecided") {
                        color = "#fdcb9e";
                    } else if (tag === "Neosoma") {
                        color = "#0f4c75";
                    }
                    return (
                        <Tag color={color} key={tag}>
                            {tag.toUpperCase()==="BRATS"?sampleName.toUpperCase():tag.toUpperCase()}
                        </Tag>
                    );
                })}
            </>
        )
    },
    {
        title: "Time Taken",
        dataIndex: "Time",
        key: "3",
        align: 'center'
    }
];
 
    return (!ArrayFilled ? <TableSkeletonview /> : <div><Table size="small" columns={columns} dataSource={Comments} />
    <button onClick={()=>ExcelExporter.exportData(`${sampleName}_userComments.xlsx`,"userComments",columns,Comments)}  className="smallerBTN" >Export to Excel</button></div>);
}