import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const Skeletonview = (props) => {
    return (<SkeletonTheme color="#f1f6f9" highlightColor="#dae1e7">
        <div className="skletetonStyle">
            <Skeleton style={{ height: props.height, width: props.width }} count={props.count} />
        </div>
    </SkeletonTheme>);
}

const TableSkeletonview = () => {
    return (<SkeletonTheme color="#f1f6f9" highlightColor="#dae1e7">
        <Skeleton style={{ height: '30px' }} count={8} />
    </SkeletonTheme>);
}

export default Skeletonview;
export { TableSkeletonview };