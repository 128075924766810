import React, { useState, useContext, useEffect } from "react";
import { LockOutlined, UnlockOutlined, FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons';
import { Tooltip, Slider } from "antd";
import Skeletonview from "./SkeleltonView";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { DataContext } from "./HomePage";
import './slidersStyles.scss';
//This is the final version
const SampleCard = (props) => {
    const { isChained, setChained, imageIndex, setimageIndex
        , Gopacity, setGopacity, Gbrightness, setGbrightness,
        sampleData, setsampleData, GConstrast, setGContrast, setforceRender } = useContext(DataContext);
    var imageurls = [];
    var AllSamples = [];
    var overlayedData = [];
    var dataSampleListener = props.data[5];
    var Seg = document.getElementById("OverLayed_L" + props.title);
    var img = document.getElementById("imageID_" + props.title);
    const [value, setValue] = useState(60);
    const [opacity, setOpacity] = useState(0);
    const [title, setTitle] = useState("T1c");
    const [currentData, setDataType] = useState(0);
    const [FullScreen, setFullScreen] = useState(false);
    const [currentBrightness, setBrightness] = useState(100);
    const [currentContrast, setcurrentContrast] = useState(100);
    AllSamples = props.data;

    getImages();
    useEffect(() => {
        if (isChained) {
            changedata(sampleData)
        } else {
            if (imageurls.length > 0 && overlayedData.length > 0) {
                changedata(title)
            }
        }
    }, [sampleData, isChained, imageurls.length, title, overlayedData.length]);

    useEffect(() => {
        setValue(77);
        setBrightness(100);
        setGbrightness(100);
        setimageIndex(77);
    }, [dataSampleListener, setGbrightness, setimageIndex]);

    const TopBtns = () => {
        return (<div className="TopBtnsStyle">
            <Tooltip title={isChained ? "click to unlock" : "click to lock"} color="#109abc" >
                <div className="TopBtn" onClick={() => ChainSelected()}>{isChained ? <UnlockOutlined className="TopIcon" /> : <LockOutlined className="TopIcon" />}</div>
            </Tooltip>
            <Tooltip title={FullScreen ? "click to dimish" : "click to enlarge"} color="#109abc">
                <div className="TopBtn" onClick={() => setFullScreen(previousValue => !previousValue)}>{FullScreen ?
                    <FullscreenExitOutlined className="TopIcon" /> : <FullscreenOutlined className="TopIcon" />}</div>
            </Tooltip>
        </div>)
    }

    const Styles = {
        overLayedImg: {
            opacity: opacity,
        }, setBrightness: {
            filter: `brightness(${currentBrightness}%) contrast(${currentContrast}%)`,
        }, ChainedoverLayedImg: {
            opacity: Gopacity,
        }, ChainedsetBrightness: {
            filter: `brightness(${Gbrightness}%) contrast(${GConstrast}%)`,
        }
    }

    if (imageurls.length === 0) {
        return (<div style={{ marginBottom: '170px', marginRight: '40px' }}>
            <Skeletonview height='50px' width='320px' count={8} />
        </div>);
    } else {
        return (
            <div className={FullScreen ? "FocusMode" : "containerStyle"} onMouseEnter={() => setforceRender(value => value + 1)}>
                <div hidden={imageurls.length === 0} className="menuStyle">
                    <DropdownButton id="buttonMenu" title={isChained ? sampleData : title} size='sm'>
                        <Dropdown.Item onClick={(e) => { changedata(e.target.textContent) }}>T1c</Dropdown.Item>
                        <Dropdown.Item onClick={(e) => { changedata(e.target.textContent) }}>Flair</Dropdown.Item>
                        <Dropdown.Item onClick={(e) => { changedata(e.target.textContent) }}>T2</Dropdown.Item>
                        <Dropdown.Item onClick={(e) => { changedata(e.target.textContent) }}>T1</Dropdown.Item>
                    </DropdownButton>
                    <TopBtns />
                </div>
                <div className="secondStyle">
                    {imageurls[imageIndex] !== undefined ?
                        <img style={isChained ? Styles.ChainedsetBrightness : Styles.setBrightness} id={"imageID_" + props.title} className="mriStyle" alt=""
                            src={isChained ? (imageurls[imageIndex] !== undefined ? imageurls[imageIndex].src : "")
                                : imageurls[value] !== undefined ? imageurls[value].src : ""} /> : <Skeletonview height='250px' width='250px' count={1} />}
                    <img id={"OverLayed_L" + props.title} className="overLayedImg"
                        style={isChained ? Styles.ChainedoverLayedImg : Styles.overLayedImg} alt=""
                        src={isChained ? (overlayedData[imageIndex] !== undefined ? overlayedData[imageIndex].src : <Skeletonview height='250px' width='250px' count={1} />)
                            : (overlayedData[value] !== undefined ? overlayedData[value].src : <Skeletonview height='250px' width='250px' count={1} />)} />
                    <div className="SlideandLabel">
                        <Slider
                            min={0}
                            max={1}
                            step={0.01}   
                            value={isChained ? Gopacity : opacity}
                            onChange={(changeEvent) => isChained ? setGopacity(changeEvent) : setOpacity(changeEvent)}
                            vertical={true}
                            className="VSliderClass"
                        />
                        <label className="SegmentationText">Segmentation</label>
                    </div>
                </div>
                <div className="botSliderContainer">
                    <label className="sliderText">Slice {`${isChained ? imageIndex + 1 : value + 1}/${imageurls.length}`}</label>
                    <Slider
                        min={0}
                        max={imageurls.length - 1} 
                        value={isChained ? imageIndex : value}
                        onChange={(changeEvent) => ValueChange(changeEvent)}
                        className="SliderClass" />
                    <div style={{ display: "flex" }}>
                        <div className="botSliderContainer">
                            <label className="sliderText">Brightness</label>
                            <Slider
                                min={0}
                                max={300} 
                                value={isChained ? Gbrightness : currentBrightness}
                                onChange={(changeEvent) => changeBrightness(changeEvent)}
                                className="SliderClass2" />
                        </div>  <div className="botSliderContainer">
                            <label className="sliderText">Contrast</label>
                            <Slider
                                min={50}
                                max={250} 
                                value={isChained ? GConstrast : currentContrast}
                                onChange={(changeEvent) => changeContrast(changeEvent)}
                                className="SliderClass2" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ChainSelected() {
        setChained(previousChain => !previousChain);
        setsampleData(title);
        setimageIndex(value);
        setGbrightness(currentBrightness);
        setGopacity(opacity);
    }

    function ValueChange(changeEvent) {
        if (img !== null && Seg !== null) {
            if (isChained) {
                setimageIndex(changeEvent);
                img.src = imageurls[imageIndex] !== undefined ? imageurls[imageIndex].src : "";
                Seg.src = overlayedData[imageIndex] !== undefined ? overlayedData[imageIndex].src : "";
            } else {
                setValue(changeEvent);
                img.src = imageurls[changeEvent] !== undefined ? imageurls[changeEvent].src : "";
                Seg.src = overlayedData[changeEvent] !== undefined ? overlayedData[changeEvent].src : "";
            }
        } else {
            setValue(value);
        }
    }
    function changeBrightness(changeEvent) {
        if (isChained) {
            setGbrightness(changeEvent)
        } else {
            setBrightness(changeEvent);
        }
    }
    function changeContrast(changeEvent) {
        if (isChained) {
            setGContrast(changeEvent)
        } else {
            setcurrentContrast(changeEvent);
        }
    }


    function changedata(newData) {
        if (isChained) {
            if (imageIndex > 149) {
                setimageIndex(149);
            }
            setsampleData(newData);
            if (newData === "T1") {
                switchArray(3, imageIndex);
                changeBrightness(81);
                changeContrast(149);
            } else if (newData === "T1c") {
                switchArray(0, imageIndex);
                changeBrightness(97);
                changeContrast(109);
            } else if (newData === "T2") {
                switchArray(2, imageIndex);
                changeBrightness(141);
                changeContrast(145);
            } else {
                switchArray(1, imageIndex);
                changeBrightness(84);
                changeContrast(130);
            }
        } else {
            if (value > 149) {
                setValue(149);
            }
            setTitle(newData);
            if (newData === "T1") {
                switchArray(3, value)
                changeBrightness(81);
                changeContrast(149);
            } else if (newData === "T1c") {
                switchArray(0, value)
                changeBrightness(97);
                changeContrast(109);
            } else if (newData === "T2") {
                switchArray(2, value)
                changeBrightness(141);
                changeContrast(145);
            } else {
                switchArray(1, value);
                changeBrightness(84);
                changeContrast(130);
            }
        }
    }

    function switchArray(index, value) {
        AllSamples[index].sort();
        setDataType(index);
        if (img !== null) {
            img.src = AllSamples[index][value];
        }
    }

    function getImages() {
        props.data[4].forEach(element => {
            const ActualImage = new Image();
            ActualImage.src = "" + element;
            overlayedData.push(ActualImage)
        });
        AllSamples[currentData].forEach(element => {
            const ActualImage = new Image();
            ActualImage.src = "" + element;
            imageurls.push(ActualImage)
        });
    }
}


export { SampleCard };