import React, { useState, useRef } from "react";
import Header from "../SurveyPage/Header";
import InfoCard from "./StaticCard";
import { Carousel } from 'antd';
import { Button } from "react-bootstrap";
import loginImg from "../../Assets/neosoma.png";
import { useLocation, useHistory } from "react-router-dom";
import firebase from "../../Firebase.jsx";
import '../SurveyPage/slidersStyles.scss';



var db = firebase.firestore();

const contentStyle = {
    divStyle: {
        height: '150px',
        textAlign: 'center',
    },
    topLabel: {
        marginTop: '20px',
        fontStyle: "italic",
        fontWeight: 'bold',
        color: '#109abc'
    },
    botLabel:
    {
        color: "rgba(0, 0, 0, 0.45)",
        fontStyle: "italic",
        justifyContent: 'center'
    },
};


const CustomizedCarousel = () => {  
    const history = useHistory();
    var locationstate = useLocation().state;
    var user = "Empty";
    if (useLocation().state === undefined) {
        history.push("/");
    } else {
        user = locationstate.userEmail;
    }
    const [currentPage, setcurrentPage] = useState(0);
    const slider = useRef();

    function UpdateUserInformation() {
        db.collection("users").doc(user).update({
            InstructionsFinished: true,
        })
        history.push('/home',
            { userEmail: user })
    }

    const PageElement = (props) => {
        return (<div>
            <div className="HeaderStyle">
                <InfoCard segmentationVisible={props.isSegVisible} step1={props.step1} step2={props.step2} step3={props.step3}
                    step4={props.step4} step5={props.step5} step6={props.step6} step7={props.step7} />
                <div hidden={!props.cardvisible}>
                    <InfoCard segmentationVisible={props.isSegVisible} />
                </div>
            </div>
            <div style={contentStyle.divStyle}>
                <h6 style={contentStyle.topLabel}>{props.currentInstruction}</h6>
                <div className="BottomDiv">
                    <label style={contentStyle.botLabel}>{props.currentPage}/7</label>
                    <Button onClick={() => props.showBTN ? slider.current.goTo(currentPage + 1) : UpdateUserInformation()} id="submitBtn" type="button" >{props.showBTN ? "Next" : "Let's Start"}</Button>
                </div>
            </div>
        </div>);
    }

    return (
        <Carousel afterChange={(index) => {
            setcurrentPage(index);
        }} effect="fade" ref={ref => slider.current = ref} >
            <PageElement isSegVisible={false} cardvisible={false} currentPage={1} step1={(currentPage + 1) === 1} showBTN={true}
                currentInstruction={"Using the drop-menu you can switch between sequences (T1,T1c,T2 & Flair)"} />
            <PageElement isSegVisible={false} cardvisible={true} currentPage={2} step2={(currentPage + 1) === 2} showBTN={true}
                currentInstruction={"Click on the unlock button to unlink the controls for the two studies"} />
            <PageElement isSegVisible={false} cardvisible={false} currentPage={3} step3={(currentPage + 1) === 3} showBTN={true}
                currentInstruction={"You can maximize/minimize the viewer using this button"} />
            <PageElement isSegVisible={true} cardvisible={false} currentPage={4} step4={(currentPage + 1) === 4} showBTN={true}
                currentInstruction={"You can control the segmentation opacity using this slider"} />
            <PageElement isSegVisible={false} cardvisible={false} currentPage={5} step5={(currentPage + 1) === 5} showBTN={true}
                currentInstruction={"You can scroll through the slices using this slider"} />
            <PageElement isSegVisible={false} cardvisible={false} currentPage={6} step6={(currentPage + 1) === 6} showBTN={true}
                currentInstruction={"You can control the image brightness with this slider"} />
            <PageElement isSegVisible={false} cardvisible={false} currentPage={7} step7={(currentPage + 1) === 7} showBTN={false}
                currentInstruction={"You can control the image contrast with this slider"} />
        </Carousel>);
}

const GetStarted = () => {
    const [firstPagevisible, setfirstPagevisible] = useState(true);
    const imageStyle = {
        width: '40px',
    };
    const Terms = () => {
        return (<div style={{ display: "flex", flexDirection: 'column', alignItems: 'center' }}>
            <div className="TermsStyle">
                <label>Thank you for joining this short Neosoma project (expected time to complete is 20 mins).</label>
                <label>In this project we are comparing Neosoma and Multimodal Brain Tumor Segmentation
                Challenge (BraTS) segmentations using 20 cases from the BraTS2020 challenge.</label>
                <label>We appreciate your time and support of our efforts in developing this fully automated AI
                solution for glioma segmentation and in bringing it to everyday clinical practice. Your input will
                be instrumental to our product development.</label>
                <label>You will be presented with 20 MRIs (4 sequences per MRI, T1post contrast and FLAIR are the most
                critical to review). Neosoma and BraTS segmentations of the same MRI will be presented side
                by side (in a random right or left arrangement between cases). We ask that you select the
                segmentation that you believe qualitatively best overlaps with the tumor compartments.</label>
                <label>There will be 3 compartments per tumor.<br></br>
                1. Enhancing compartment is presented in <label style={{ color: 'red' }}>red color</label>.<br></br>
                2. Edema compartment is presented in <label style={{ color: 'blue' }}>blue color</label>.<br></br>
                3. Necrosis compartment is presented in <label style={{ color: 'yellow', padding: "1px", backgroundColor: 'grey' }}>yellow color</label>.</label>

                <label>Few areas that will require your attention for proper selection are differences in
                multifocal/multicentric disease, FLAIR edema involving the cortex in addition to the white
                matter, sub-ependymal spread of tumor and areas of necrotic tissue selection.</label>

                <label>In the next page, you will be presented with a brief tutorial to familiarize you with the
                capabilities of the viewer that we built for this project. This will be followed by the actual test
                cases.</label>

                <label style={{ fontWeight: 'bold' }}>
                    NOTE: PLEASE USE GOOGLE CHROME WITH 100% ZOOM LEVEL.
                </label>
                <label>All the best.</label>
                <label>The Neosoma Team</label>
                <img style={imageStyle} alt="" src={loginImg} />
            </div>
            <Button onClick={() => setfirstPagevisible(false)} id="submitBtn" type="button" >Start</Button>
        </div>)
    }


    return (
        <div className="PageStyle">
            <Header isVisible={firstPagevisible} />
            {!firstPagevisible ?
                <CustomizedCarousel /> :
                <Terms />}
        </div>
    );
}

export default GetStarted;