import React, { useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import firebase from "../../Firebase.jsx";
//This is the final version
var db = firebase.firestore();

export default function Piechart() {
    const [loaded, setloaded] = useState(false);
    const [showDiv, setshowDiv] = useState(false);
    const [TotalVote, setTotalVote] = useState(0);
    const [NeosomaVote, setNeosomaVote] = useState(0);
    const [BratVote, setBratVote] = useState(0);
    const [udVote, setudVote] = useState(0);
    const [viewData, setviewData] = useState('');

    getLastSample();
    useEffect(() => {
        db.doc("Constants/data").get().then((doc)=>{
            if(doc.exists){
                var sampleName=doc.data().sampleName;
                if (loaded === true) {
                    renderChart();
                    function renderChart() {
                        setshowDiv(true);
                        if (document.querySelector("#chart") !== null) {
                            var options = {
                                chart: {
                                    width: 500,
                                    type: "pie"
                                },
                                colors: ["#00b7c2", "#0f4c75", "#fdcb9e"],
                                series: [BratVote, NeosomaVote, udVote],
                                labels: [sampleName, "Neosoma", "Undecided"]
                            };
                            const chart = new ApexCharts(document.querySelector("#chart"), options);
                            chart.render();
                        }
                    }
                }
                db.collection("Constants").doc("data").get().then((doc) => {
                    if (doc.exists) {
                        setviewData(doc.data().readFrom);
                    }
        
                });
            }
        });
    }, [loaded, BratVote, NeosomaVote, udVote]);


    function getLastSample() {
        if (viewData !=='') {
            var docRef = db.collection(viewData).doc("TotalVotes");
            docRef.get().then(function (doc) {
                if (doc.exists) {
                    setTotalVote(doc.data().TotalVotes);
                    doc.data().Neosoma === undefined ? setNeosomaVote(0) : setNeosomaVote(doc.data().Neosoma);
                    doc.data().Brats === undefined ? setBratVote(0) : setBratVote(doc.data().Brats);
                    doc.data().Undecided === undefined ? setudVote(0) : setudVote(doc.data().Undecided);
                    if (!loaded) {
                        setloaded(true);
                    }
                } else {
                    console.log("No such document!");
                }
            }).catch(function (error) {
                console.log("Error getting document:", error);
            });
        }
    }

    return (
        <div id="chart" className="Piechart" hidden={!showDiv}>
            <label className="LabelStyle" >Total Votes</label>
            <label className="LabelStyle" >{TotalVote}</label>
        </div>
    );
}