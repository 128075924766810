import React, { useState } from 'react'
import { Table, Tag } from "antd";
import firebase from "../../Firebase.jsx";
import { UserProfile } from "../../Model/Comment";
import { TableSkeletonview } from '../SurveyPage/SkeleltonView';
import { CheckSquareTwoTone, CloseSquareTwoTone } from '@ant-design/icons';
//This is the final version
var db = firebase.firestore();

export default function ProfileInfo() {
    var [Comments, setComments] = useState([]);
    var [ArrayFilled, setArrayFilled] = useState(false);
    if (!ArrayFilled) {
        getTablesInfo();
    }

    function getTablesInfo() {
        var userEntry = [];
        db.collection("users").get().then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
                const usersInformation = doc.data();
                const createdAt = usersInformation.createdAt === undefined ? "N/A" : usersInformation.createdAt;
                const lastSeen = usersInformation.lastSeen === undefined ? "N/A" : usersInformation.lastSeen;
                const SingleUser = new UserProfile(`${usersInformation.first} ${usersInformation.last}`, `${usersInformation.email}`
                    , `${usersInformation.InstructionsFinished}`, `${usersInformation.Admin}`, `${usersInformation.EmailVerified}`, createdAt, lastSeen);
                userEntry.push(SingleUser);
                setComments(() => [...userEntry]);
                setArrayFilled(true);
            });
        });
    }
    return (!ArrayFilled ? <TableSkeletonview /> : <Table size="small" columns={columns} dataSource={Comments} />);
}
const columns = [
    {
        dataIndex: "Name",
        key: "Name",
        fixed: "left",
        align: 'center',
        title: "Name",
    },
    {
        title: "Email",
        dataIndex: "Email",
        align: 'center'
    },
    {
        title: "Email Verified",
        dataIndex: "Verified",
        key: "1",
        align: 'center',
        render: (tags) => (
            <>
                {tags === "true" ? <CheckSquareTwoTone twoToneColor="#109abc" /> : <CloseSquareTwoTone twoToneColor="red" />}
            </>
        )
    },
    {
        title: "Instructions Finished",
        dataIndex: "Instructions",
        key: "2",
        align: 'center',
        render: (tags) => (
            <>
                {tags === "true" ? <CheckSquareTwoTone twoToneColor="#109abc" /> : <CloseSquareTwoTone twoToneColor="red" />}
            </>
        )
    },
    {
        title: "Admin Account",
        dataIndex: "Admin",
        key: "2",
        align: 'center',
        render: (tags) => (
            <>
                {tags === "true" ? <CheckSquareTwoTone twoToneColor="#109abc" /> : <CloseSquareTwoTone twoToneColor="red" />}
            </>
        )
    },
    {
        title: "Creation Date",
        dataIndex: "createdAt",
        key: "2",
        align: 'center',
        render: (tags) => (
            <>
                {<Tag color="blue" key={tags}>{tags}</Tag>}
            </>
        )
    }, {
        title: "Last Seen",
        dataIndex: "lastSeen",
        key: "2",
        align: 'center',
        render: (tags) => (
            <>
                {<Tag color="blue" key={tags}>{tags}</Tag>}
            </>
        )
    },
];