
class SegregationsUser {
    constructor(userName,totalNeosoma,totalUndecided,totalBrats,totalConducted,neosomaSamples,bratsSamples,undecidedSamples) {
        this.userName = userName;  
        this.totalNeosoma = totalNeosoma;
        this.totalUndecided = totalUndecided; 
        this.totalBrats = totalBrats; 
        this.totalConducted=totalConducted;
        this.neosomaSamples=neosomaSamples;
        this.bratsSamples= bratsSamples;
        this.undecidedSamples=undecidedSamples;
        this.key = Math.random();
    }
}


class SegregationsUserDetailed {
    constructor(userName,sampleName,selectedSample) {
        this.userName = userName;  
        this.sampleName = sampleName; 
        this.selectedSample=selectedSample;
        this.key = Math.random();
    }
}

class Comment {
    constructor(name, sample, input, selectedSample, Time, Specialty, Experience, selectedSegmentation, key) {
        this.name = name;
        this.sample = sample;
        this.input = input;
        this.selectedSample = selectedSample;
        this.Time = Time;
        this.Specialty = Specialty;
        this.Experience = Experience;
        this.selectedSegmentation = selectedSegmentation;
        this.key = key;
    }
}

class DetailedTable {
    constructor(samlpleNumber,sample, totalVote, BratsVotes, NeosomaVote, UDvotes, Time) {
        this.sample = sample;
        this.samlpleNumber=samlpleNumber;
        this.totalVote = totalVote;
        this.BratsVotes = BratsVotes;
        this.NeosomaVote = NeosomaVote;
        this.Time = Time;
        this.UDvotes = UDvotes;
        this.key = Math.random()
    }
}

class UserProfile {
    constructor(Name, Email, Instructions, Admin, Verified, createdAt, lastSeen) {
        this.Name = Name;
        this.Email = Email;
        this.Instructions = Instructions;
        this.Admin = Admin;
        this.Verified = Verified;
        this.createdAt = createdAt;
        this.lastSeen = lastSeen;
        this.key = Math.random()
    }
}

export default Comment;
export { DetailedTable, UserProfile,SegregationsUser,SegregationsUserDetailed };