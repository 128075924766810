import firebase from 'firebase';

const storage = firebase.storage().ref(); 

export default class FBManager {

 

    async getImages(imageurls, Folderpath) {
        var listRef = storage.child(Folderpath);
        listRef.listAll().then(function (result) {
            for (var i = 0; i < result.items.length; i++) {
                result.items[i].getDownloadURL().then((url) => {
                    imageurls.push("" + url);
                    imageurls.sort();
                })
            }
        }).catch(function (error) {
            console.log(error)
        });
    }
}