import React from "react";
import ImageSample from "../../Assets/Image.png";
import Handimg from "../../Assets/tap.svg";
import SegImage from "../../Assets/Segmentation.png";
import { Tooltip, Slider } from "antd";
import { DropdownButton, Row } from "react-bootstrap";
import { UnlockOutlined, FullscreenExitOutlined } from '@ant-design/icons';
import '../SurveyPage/slidersStyles.scss';

const InfoCard = (props) => {
    return (
        <div className="disableDiv">
            <Row className="justify-content-center" >
                <div className="containerStyle"  >
                    <div className="menuStyle">
                        <Tooltip mouseLeaveDelay={0.0} visible={props.step1} placement="left" title={<img className="HandImage" alt="" src={Handimg} />} color="rgba(20, 40, 80,0.5)"  >
                            <DropdownButton id="buttonMenu" title="T1" size='sm'>
                            </DropdownButton>
                        </Tooltip>
                        <div className="TopBtnsStyle">
                            <Tooltip visible={props.step2} placement="left"
                                title={<img alt="" className="HandImage" src={Handimg} />} color="rgba(20, 40, 80,0.5)"  >
                                <div className="TopBtn"><UnlockOutlined className="TopIcon" /></div>
                            </Tooltip>
                            <Tooltip visible={props.step3} placement="bottom"
                                title={<img className="HandImage2" alt="" src={Handimg} />} color="rgba(20, 40, 80,0.5)">
                                <div className="TopBtn">{<FullscreenExitOutlined className="TopIcon" />}</div>
                            </Tooltip>
                        </div>
                    </div>
                    <div className="secondStyle">
                        <img className="mriStyle" alt="" src={ImageSample} />
                        <img className="overLayedImg" alt="" src={props.segmentationVisible === false ? "" : SegImage} />
                        <div className="SlideandLabel">
                            <Tooltip visible={props.step4} placement="bottom"
                                title={<img className="HandImage2" alt="" src={Handimg} />} color="rgba(20, 40, 80,0.5)">
                                <Slider
                                    min={0}
                                    max={1}
                                    step={0.01}
                                    value={155}
                                    vertical={true}
                                    className="VSliderClass" /></Tooltip>
                            <label className="SegmentationText">Segmentation</label>
                        </div>
                    </div>
                    <div className="botSliderContainer">
                        <label className="sliderText">Slice 66/155</label>
                        <Tooltip visible={props.step5} placement="left"
                            title={<img className="HandImage" alt="" src={Handimg} />} color="rgba(20, 40, 80,0.5)">
                            <Slider
                                min={0}
                                max={155}
                                value={66}
                                className="SliderClass" /></Tooltip>
                        <div style={{ display: "flex" }}>
                            <div className="botSliderContainer">
                                <label className="sliderText">Brightness</label>
                                <Tooltip visible={props.step6} placement="left"
                                    title={<img className="HandImage" alt="" src={Handimg} />} color="rgba(20, 40, 80,0.5)">
                                    <Slider
                                        min={0}
                                        max={300}
                                        value={123}
                                        className="SliderClass2" />
                                </Tooltip>
                            </div>  <div className="botSliderContainer">
                                <label className="sliderText">Contrast</label>
                                <Tooltip visible={props.step7} placement="left"
                                    title={<img className="HandImage" alt="" src={Handimg} />} color="rgba(20, 40, 80,0.5)">
                                    <Slider
                                        min={50}
                                        max={250}
                                        value={150}
                                        className="SliderClass2" />
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </Row>
        </div>);
}
export default InfoCard;